// Home Page
const myName = "Yijing Wu";

const myTitle = "A software developer.";

const myBriefIntro =
  "I’m a software developer specializing in web development, cloud computing and Generative AI. I'm passionate about delivering ideas into reality via coding.";
// "I’m a software developer specializing in both front-end and back-end web/mobile development. Also familiar with Computer Vision and Signal Processing algorithms. I'm passionate about delivering ideas into reality via coding.";

// About Page
const myAboutMe = [
  "Hello! My name is Yijing. Welcome to my page!",
  "I'm a software developer as well as a master's student at Northeastern University. I have experience with constructing web and mobile applications, cloud DevOps as well as Generative AI. ",
  "I enjoy the process of developing, akin to solving puzzles, requiring a comprehensive understanding of structure, beginning with small components, and gradually assembling them into a cohesive whole.",
  "Previously, I honed my skills at Upduo, where I served as a full-stack engineer, crafting engaging and effective peer learning experiences for users. ",
  "Today, I'm diving into Cloud DevOps at Autodesk. My role involves building AWS cloud CI/CD pipelines and unleashing the potential of Gen AI.",
  //   "I used to work as an algorithm engineer, focusing on camera calibration for self-driving vehicles, and also as a research assistant specializing in EEG signal processing.",
];

const myEducationList = [
  {
    school: "Northeastern University",
    degree: "Master",
    major: "Information Systems",
    startDate: "September 2021",
    endDate: "December 2023",
  },
  {
    school: "Tongji University",
    degree: "Bachelor",
    major: "Communication Engineering",
    startDate: "September 2017",
    endDate: "June 2021",
  },
];

const mySkillList = [
  "Python",
  "React",
  "JavaScript",
  "React Native",
  "TypeScript",
  "Node.js",
  "Docker",
  "Django",
  "CI/CD",
  "Linux",
  "Jenkins",
  "Terraform",
  "AWS",
  "Generative AI",
];

export {
  myName,
  myTitle,
  myBriefIntro,
  myAboutMe,
  myEducationList,
  mySkillList,
};
